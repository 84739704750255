<template>
    <div>
        <div class="row flex-grow-0 mb-2">
            <div class="col">
                <!-- TableList -->
                <TableListComponent
                    ref="TableListComponent"
                    :title="''"
                    :columns="columns"
                    :rows="detailInfo.ptMaps"
                    :keyField="'ptIdx'"
                    :transCodes="transCodes"
                    :mode="mode"
                    :useMaxHeight="false"
                    :fixedHeader="false"
                    :toggleFields="['enabled']"
                    :useFilter="false"
                    :controls="controls"
                    @row-click="handleListItemClick"
                    @toggle-changed="handleItemToggle"
                    @button-click="handleListButtonClick"
                />

                <b-modal ref="PointDetailModal" centered @hidden="closePtMapModal">
                    <template #modal-header>
                        <h5>관제점 정보</h5>
                    </template>
                    <template #modal-footer>
                        <div style="display: flex; flex: 1;">
                            <button
                                v-show="isEditMode"
                                type="button"
                                class="btn btn-primary"
                                style="flex: 1;"
                                :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                                @click="handleAddListItem"
                            >
                                저장
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary"
                                style="flex: 1;"
                                :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                                @click="closePtMapModal"
                            >
                                닫기
                            </button>
                        </div>
                    </template>

                    <PointDetailPanel ref="PointDetailPanel" :mode="mode" />
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import PointDetailPanel from "./PointDetailPanel.vue";

export default {
    components: {
        TableListComponent,
        PointDetailPanel,
    },
    props: ["detailInfo", "mode"],
    created() {},
    mounted() {},
    data() {
        return {
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-warning" },
            ],
            transCodes: [
                {
                    field: "ptReltnType",
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t("관제점 ID"),
                    field: "ptIdx",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: this.$t("관제점 주소"),
                    field: "ptAddr",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: this.$t("관제점명"),
                    field: "ptName",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("관계 유형"),
                    field: "ptReltnType",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("단위"),
                    field: "unit",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("계측값"),
                    field: "ptVal",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center",
                },
            ],
            tempPtMapIndex: null,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {},
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if (toggle || check) return;

            this.tempPtMapIndex = index;
            let findPtMap = this.detailInfo.ptMaps.find((item) => item.ptIdx == row.ptIdx);
            await this.$refs.PointDetailModal.show();
            await this.$refs.PointDetailPanel.setData(findPtMap);
        },
        async closePtMapModal() {
            await this.$refs.PointDetailModal.hide();
            if (this.tempPtMapIndex !== null || undefined) this.tempPtMapIndex = null;
        },
        async handleAddListItem() {
            const ptMap = this.$refs.PointDetailPanel.getData();
            const ptIdxs = this.$refs.PointDetailPanel.getPtIdxs();

            // if(ptMap) {
            //     if(this.tempPtMapIndex !== null || undefined) {
            //         this.$set(this.detailInfo.ptMaps, this.tempPtMapIndex, ptMap);
            //         this.tempPtMapIndex = null;
            //     } else {
            //         // Check Duplication (propCode, propName)
            //         let check = this.detailInfo.ptMaps? this.detailInfo.ptMaps.find(prop => prop.ptIdx === ptMap.ptIdx) : null;
            //         if (check) {
            //             // TODO: Alert Message
            //             console.error('TODO: Alert Message');
            //             console.error(`In Property is already exists in ptMaps. propCode:${ptMap.ptIdx}`);
            //         } else {
            //             this.$set(this.detailInfo.ptMaps, this.detailInfo.ptMaps.length, ptMap);
            //         }
            //     }
            // }

            if (this.tempPtMapIndex !== null || undefined) {
                try {
                    let result = await backEndApi.points.getPointInfoById(ptMap.ptIdx);
                    if (result.status == 200) {
                        const item = {
                            ptReltnType: ptMap.ptReltnType,
                            enabled: ptMap.enabled,
                            ptIdx: result.data[0].ptIdx,
                            ptAddr: result.data[0].ptAddr,
                            ptName: result.data[0].ptName,
                            unit: result.data[0].unitSmallCode,
                        };
                        this.$set(this.detailInfo.ptMaps, this.tempPtMapIndex, item);
                        this.tempPtMapIndex = null;
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                let items = [];
                if (ptIdxs.includes(",")) items = ptIdxs.split(", ").map((item) => item.trim());
                else items.push(ptIdxs);
                try {
                    items.forEach(async (item) => {
                        let result = await backEndApi.points.getPointInfoById(item);
                        if (result.status == 200) {
                            const temp = {
                                ptReltnType: ptMap.ptReltnType,
                                enabled: ptMap.enabled,
                                ptIdx: result.data[0].ptIdx,
                                ptAddr: result.data[0].ptAddr,
                                ptName: result.data[0].ptName,
                                unit: result.data[0].unitSmallCode,
                            };
                            this.$set(this.detailInfo.ptMaps, this.detailInfo.ptMaps.length, temp);
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            this.$refs.PointDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs["TableListComponent"].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.ptMaps = that.detailInfo.ptMaps.filter((prop) => {
                            return !checked.find((checkedProp) => checkedProp.ptIdx === prop.ptIdx);
                        });
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e);
            }
        },
        async registNew() {
            const that = this;

            that.$refs.PointDetailModal.show();
            that.$nextTick(() => {
                that.$refs.PointDetailPanel.setData(null);
            });
        },
        handleItemToggle(key, field, status) {
            const that = this;
            const found = that.detailInfo.ptMaps.find((item) => item.ptIdx === key);
            found.enabled = status;
        },
    },
};
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>
